import { Link } from "react-router-dom";
import Instagram from "../../../assets/instagram-client.svg";
import Whatsapp from "../../../assets/whatsapp-client.svg";
import Telegram from "../../../assets/telegram-client.svg";
import Cash from "../../../assets/pay/cash.svg";
import Visa from "../../../assets/pay/visa.svg";
import MasterCard from "../../../assets/pay/Mastercard.svg";
import Copyright from "../../../assets/copyright.svg";
import Mir from "../../../assets/pay/mir.svg";
import "./Footer.scss";

const Footer = () => {
  return (
    <footer className="footer">
      <section className="footer__wrapper">
        <div className="footer__container">
          <div className="footer__text-container">
            <a href="mailto:tvoyauborka@yandex.ru" className="footer__text">
              Жалобы, отзывы и предложения
            </a>
            <a href="mailto:yusupovgroup01@gmail.com" className="footer__text">
              Для сотрудничества
            </a>
            <Link to="/feedback" className="footer__text">
              Отзывы
            </Link>
            <Link to="/uslugi" className="footer__text">
              Наши услуги
            </Link>
            <a href="/work-with-us" className="footer__text">
              Работа у нас
            </a>
          </div>
          {/* <a
            href="https://www.instagram.com/tvoyauborka.ru"
            target="_blank"
            rel="noreferrer"
          >
            <img
              className="footer__img-icon"
              src={Instagram}
              width="40"
              alt="Instagram"
            />
          </a> */}
          <a href="https://wa.me/+79152640011" target="_blank" rel="noreferrer">
            <img
              className="footer__img-icon"
              src={Whatsapp}
              width="40"
              alt="Whatsapp"
            />
          </a>
          <a
            href="https://t.me/tvoyauborka_RU"
            target="_blank"
            rel="noreferrer">
            <img
              className="footer__img-icon"
              src={Telegram}
              width="40"
              alt="Telegram"
            />
          </a>
        </div>
        <div className="footer__container">
          <div className="footer_number">+7 915 264-00-11</div>
          <Link className="footer__link" to="/agreement">
            Договор оферты
          </Link>
          <Link className="footer__link" to="/policy">
            Политика конфиденциальности
          </Link>
          <Link className="footer__link" to="/prices">
            Какие услуги мы предоставляем
          </Link>
          <Link className="footer__link" to="/karta-sayta">
            Карта сайта
          </Link>
          <Link className="footer__link" to="/contacts">
            Контакты
          </Link>
          <Link className="footer__link" to="/about">
            О нас
          </Link>
        </div>
        <div className="footer__container">
          <div className="footer__text-container">
            {/* <div className="footer__text">Мы принимаем:</div> */}
            <div className="footer__img-icons">
              {/* <img
                className="footer__img-icon"
                src={Cash}
                width="40"
                alt="Cash"
              /> */}
              <img
                className="footer__img-icon"
                src={Visa}
                width="40"
                alt="Visa"
              />
              <img
                className="footer__img-icon"
                src={MasterCard}
                width="40"
                alt="MasterCard"
              />
              <img
                className="footer__img-icon"
                src={Mir}
                width="40"
                alt="Mir"
              />
            </div>
          </div>
          <div className="footer__text">Исполнитель: И.П.«Юсупова.Г.Г.»</div>
          <div className="footer__text">
            г Москва., Пресненская наб., дом 12,Башня- Восток 25 этаж, офис 2.
          </div>
          <div className="footer__text">ОГРНИП 323774600745562</div>
          <div className="footer__text">ИНН 772020540918</div>
          <div className="footer__text">
            <img src={Copyright} alt="Copyright" />
            ТвояУборка 2018-2025
          </div>
        </div>
      </section>
    </footer>
  );
};

export default Footer;
