import "./Contacts.scss";
import { Helmet } from "react-helmet-async";

export const Contacts = () => {
  return (
    <>
      <Helmet>
        <title>Контакты – свяжись и закажи клининг от Твоя Уборка</title>
        <meta
          name="description"
          content="Свяжитесь с нами для консультаций и заказов. Мы готовы помочь вам в любой момент! Свяжитесь с нами для получения консультации или оформления заказа!"
        />
      </Helmet>
      <div className="contacts-container">
        <h1 className="contacts-title">Контакты</h1>
        <div className="contact-info">
          <h2>Исполнитель: И.П.«Юсупова.Г.Г.»</h2>
          <p>
            <strong>Адрес:</strong> г. Москва, Пресненская наб., дом 12,
            Башня-Восток 25 этаж, офис 2
          </p>
          <p>
            <strong>Телефон:</strong>{" "}
            <a href="tel:+71234567890">7 915 264-00-11</a>
          </p>
        </div>
        <div className="location-info">
          <h3>Как нас найти</h3>
          <p>
            Мы находимся в деловом центре Москва-Сити. Ближайшие станции метро:
            "Деловой центр", "Выставочная", "Международная".
          </p>
        </div>
        <div className="working-hours">
          <h3>Часы работы</h3>
          <p>Понедельник - Пятница: 9:00 - 18:00</p>
          <p>Суббота - Воскресенье: Выходной</p>
        </div>
        <div className="legal-info">
          <h3>Юридическая информация</h3>
          <p>ОГРНИП: 323774600745562</p>
          <p>ИНН: 772020540918</p>
        </div>
        <footer>
          <p>© ТвояУборка 2018-2024. Все права защищены.</p>
        </footer>
      </div>
    </>
  );
};
